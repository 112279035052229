import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import Loader from './Loader';
import AddressAutocomplete from './AddressAutocomplete';

export default function UserForm({ setShow, setFormData }) {
    const [addressSelected, setAddressSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const form = useForm({
        defaultValues: {
            'utility': 'CLEAResult Power & Light',
            'country': 'US'
        }
    });
    const { register, handleSubmit, formState, setValue, control, clearErrors, setError } = form;
    const { errors } = formState;

    const onSubmit = (data) => {
        setFormData(data);
        setShow(true);
    };

    const handleAddressSelect = (selectedAddress) => {
        setValue('address', selectedAddress?.street_line);
        setValue('city', selectedAddress?.city);
        setValue('state', selectedAddress?.state);
        setValue('zipcode', selectedAddress?.zipcode);
        setValue('latitude', selectedAddress?.latitude);
        setValue('longitude', selectedAddress?.longitude)
        setAddressSelected(true);
        clearErrors();
    };

    return (
        <div className='user-form'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formControl">
                    <label htmlFor="username">Name</label>
                    <input type="text" id="username" placeholder='Enter your full name' {...register('username', {
                        required: {
                            value: true,
                            message: 'Name is required'
                        },
                        pattern: {
                            value:
                                /^[A-Za-z ]+$/,
                            message: "Name should contain only letters",
                        }
                    })} />
                    <p className='error'>{errors.username?.message}</p>
                </div>
                <div className="formControl">
                    <label htmlFor="email">E-mail</label>
                    <input type="text" id="email" placeholder='Enter Email'  {...register('email', {
                        required: {
                            value: true,
                            message: 'Email is required'
                        },
                        pattern: {
                            value:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email format",
                        }
                    })} />
                    <p className='error'>{errors.email?.message}</p>
                </div>
                <div className="formControl">
                    <label htmlFor="country">Country</label>
                    <input type="text" id="country" placeholder='Enter your country' disabled {...register('country')} />
                    <p className='error'>{errors.country?.message}</p>
                </div>
                <div className="formControl">
                    <label htmlFor="address">Address</label>
                    <Controller
                        name="address"
                        control={control}
                        rules={{ required: 'Address is required' }}
                        render={({ field }) => (
                            <AddressAutocomplete
                                onAddressSelect={handleAddressSelect}
                                setIsLoading={setIsLoading}
                                setError={setError}
                                clearErrors={clearErrors}
                                setAddressSelected={setAddressSelected}
                            />
                        )}
                    />
                    <p className="error">{errors.address && errors.address.message}</p>
                </div>

                {isLoading ? (<Loader />) : (
                    <>
                        <div className="formControl" hidden={!addressSelected}>
                            <label htmlFor="state">State</label>
                            <input type="text" id="state" placeholder='Enter your state' disabled  {...register('state')}
                            />
                            <p className='error'>{errors.state?.message}</p>
                        </div>
                        <div className="formControl" hidden={!addressSelected}>
                            <label htmlFor="city">City</label>
                            <input type="text" id="city" placeholder='Enter your city' disabled {...register('city')}
                            />
                            <p className='error'>{errors.city?.message}</p>
                        </div>
                        <div className="formControl" hidden={!addressSelected}>
                            <label htmlFor="zipcode">ZIP Code</label>
                            <input type="text" id="zipcode" placeholder='Enter Zipcode' disabled {...register('zipcode')} />
                            <p className='error'>{errors.zipcode?.message}</p>
                        </div>
                    </>
                )}
                <div className="formControl" hidden>
                    <label htmlFor="utility">Utility</label>
                    <input type="text" id="utility" placeholder='Utility'  {...register('utility')} />
                    <p className='error'>{errors.utility?.message}</p>
                </div>
                <div className='d-flex justify-content-center'>
                    <button className='submit-btn' disabled={!addressSelected || errors?.address?.message }>Submit</button>
                </div>
            </form>
        </div>
    )
}

