import React from 'react';
import '../assets/styles/Connect.css';

// eslint-disable-next-line
export default ({ onClick }) => (
  <div className='container connect-container'>
    <div className='row d-flex justify-content-center'>
    <div className="col-sm-12 connect-content">
      <h1 className='connect-vechile-title text-center mb-4'>Connect your vehicle</h1>
      <div className='d-flex justify-content-center'>
        <button onClick={onClick} className='connect-btn'>Connect</button>
      </div>
    </div>
  </div>
  </div>
);
